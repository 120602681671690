import { DateTime } from 'luxon';
import { memo, useEffect, useMemo, useState } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../components/monthView/custom-month-view.css';
import '../components/weekView/custom-week-view.css';
import '../components/agendaView/custom-agenda-view.css';

import CustomToolbar from './toolbar/CustomToolBar';
import CustomMonthHeader from './monthView/CustomMonthHeader';
import CustomMonthEvent from './monthView/CustomMonthEvent';
import EventPopup from './EventPopup';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

const BigCalendar = ({ handleDateChange, calendarData, selectedDate, resources }) => {
  const { t } = useTranslation();

  const messages = {
    allDay: t('types.calendar.allDay'),
    previous: t('types.calendar.previous'),
    next: t('types.calendar.next'),
    today: t('types.calendar.today'),
    month: t('types.calendar.month'),
    week: t('types.calendar.week'),
    day: t('types.calendar.day'),
    agenda: t('types.calendar.agenda'),
    date: t('types.calendar.date'),
    time: t('types.calendar.time'),
    event: t('types.calendar.event'),
    showMore: (total) => `+${total} ${t('types.calendar.more')}`,
  };

  const localizer = luxonLocalizer(DateTime, {
    firstDayOfWeek: 1,
  });
  const defaultDate = new Date(selectedDate);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    /** --- create events array --- */
    if (calendarData) {
      const sessions = calendarData.map((item) => {
        const sessionDate = DateTime.fromISO(item.date);
        const sessionEnd = sessionDate.plus({ minutes: item.lesson?.plannedDuration });
        return {
          title: item.lesson ? `${item.lesson?.number}.${item.lesson?.name}` : t('types.calendar.noLesson'),
          resourceId: item.group.id,
          start: new Date(item.date),
          end: new Date(sessionEnd),
          link: item.link,
          group: item.group.name,
        };
      });
      setEvents(sessions);
    }
  }, [calendarData, t]);

  const components = useMemo(
    () => ({
      toolbar: (props) => <CustomToolbar {...props} messages={messages} />,
      month: {
        header: CustomMonthHeader,
        event: CustomMonthEvent,
      },
    }),
    []
  );

  const handleOpenPopup = (event, e) => {
    setSelectedEvent(event);
    setAnchorEl(e.currentTarget);
  };

  const closePopup = () => {
    setSelectedEvent(null);
    setAnchorEl(null);
  };

  return (
    <>
      <Calendar
        defaultDate={defaultDate}
        views={['day', 'week', 'month', 'agenda']}
        defaultView="month"
        events={events}
        localizer={localizer}
        scrollToTime={defaultDate}
        onNavigate={handleDateChange}
        components={components}
        date={defaultDate}
        resourceIdAccessor="resourceId"
        resources={resources}
        resourceTitleAccessor="resourceTitle"
        popup
        onSelectEvent={handleOpenPopup}
        culture={i18n.language === 'UA' ? 'UK' : i18n.language}
        messages={messages}
        tooltipAccessor={false}
      />
      {isOpen && selectedEvent && (
        <EventPopup event={selectedEvent} onClose={closePopup} anchorEl={anchorEl} isOpen={isOpen} />
      )}
    </>
  );
};

export default memo(BigCalendar);
