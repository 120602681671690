import { Stack, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import {
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import ProgramViewTopBar from './components/programs/topbar/ProgramViewTopBar';
import { memo, useEffect } from 'react';
import store from 'store';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import AssignmentsList from './components/assignments/AssignmentsList';

const AssignmentsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const { id, groupId, lessonId, moduleId } = useParams();
  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]) && !program;
  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const assignments = selectedLesson?.assignments;

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  const handleSelectAssignment = (e, assignment) => {
    e.stopPropagation();
    groupId
      ? navigate(`/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments/${assignment.id}`)
      : navigate(`/programs/${id}/module/${moduleId}/lesson/${lessonId}/assignments/${assignment.id}`);
  };

  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](id));
  }, [currentRole, id, program]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.assignment.namePlural')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
            selectedMaterialPriorityLabel={t('types.assignment.namePlural')}
          />
        </ErrorBoundary>
      )}
      {programLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}
          <ELementWrapper
            width="100%"
            height="100%"
            flex={3}
            padding={isDesktop ? 2 : 0}
            display={isDesktop ? 'flex' : isProgramMenuOpen && 'none'}
            sx={{ backgroundColor: isDesktop ? 'extra.white' : 'transparent', pt: !isDesktop ? 0 : 2 }}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramViewTopBar
                program={program}
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                isAssignment
                hideProgramViewTopBar={isDesktop ? false : true}
                hideToggleView={isDesktop ? false : true}
              />
            </ErrorBoundary>

            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <AssignmentsList assignments={assignments} handleSelectAssignment={handleSelectAssignment} />
            </ErrorBoundary>
          </ELementWrapper>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(AssignmentsPage);
