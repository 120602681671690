import { memo } from 'react';

import { ELementWrapper } from 'views/common/styledComponents';
import ProgramItemHeader from './ProgramItemHeader';
import ModuleNavItem from './ModuleNavItem';
import { Box } from '@mui/material';

const ProgramNavigation = ({ program }) => {
  return (
    <ELementWrapper
      sx={{
        minHeight: 64,
        flex: 1,
        height: '100%',
      }}
    >
      <ProgramItemHeader program={program} />
      <Box sx={{ overflowY: 'auto', height: '100%' }}>
        {program?.modules?.map((module) => {
          return <ModuleNavItem key={module.number} program={program} module={module} />;
        })}
      </Box>
    </ELementWrapper>
  );
};

export default memo(ProgramNavigation);
