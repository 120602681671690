import { Stack, useMediaQuery } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import HeaderList from 'views/common/HeaderList';
import ProgramNavigation from './components/navigation/ProgramNavigation';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import ProgramViewTopBar from './components/programs/topbar/ProgramViewTopBar';
import { useSelector } from 'react-redux';
import {
  MATERIALS_PRIORITY,
  MATERIAL_STATUSES,
  PROGRAM_FETCH_BY_ROLES,
  PROGRAM_IS_LOADING_BY_ROLES,
  PROGRAM_SELECTORS_BY_ROLES,
} from 'utils/constants/program';
import { userSelectors } from 'store/ducks/user';
import { useNavigate, useParams } from 'react-router-dom';
import store from 'store';
import { studentActions, studentThunks } from 'store/ducks/student';
import Loader from 'views/common/Loader';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import { useProgramMobileMenuOpenContext } from 'services/context/programMobileMenuOpenContext';
import HeaderPageMobile from 'views/common/HeaderPageMobile';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import MaterialsList from './components/materials/MaterialsList';

const MaterialsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isStudent = currentRole === 'Student';

  const program = useSelector(PROGRAM_SELECTORS_BY_ROLES[currentRole]);
  const programIsLoading = useSelector(PROGRAM_IS_LOADING_BY_ROLES[currentRole]);

  const { id, groupId, priorityName, lessonId, moduleId, materialId } = useParams();

  const [filteredMaterialsList, setFilteredMaterialsList] = useState([]);

  const selectedModule = program?.modules.find((module) => module.id === +moduleId);
  const selectedLesson = selectedModule?.lessons.find((lesson) => lesson.id === +lessonId);
  const selectedPriority = MATERIALS_PRIORITY.find((priority) => priority.urlParam === priorityName);
  const selectedMaterial = filteredMaterialsList?.find((material) => material.id === +materialId);

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { isProgramMenuOpen, toggleProgramMobileMenu } = useProgramMobileMenuOpenContext();

  useEffect(() => {
    const selectedLessonMaterials = selectedLesson?.materials?.filter(
      (material) => material.priority.name === selectedPriority?.name
    );

    setFilteredMaterialsList(selectedLessonMaterials);
  }, [selectedLesson, setFilteredMaterialsList, selectedPriority]);

  const handleSelectMaterial = (e, material) => {
    e.stopPropagation();
    groupId
      ? navigate(
          `/groups/${groupId}/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${material.id}`
        )
      : navigate(
          `/programs/${id}/module/${moduleId}/lesson/${lessonId}/priority/${priorityName}/material/${material.id}`
        );

    if (isStudent && material?.status === MATERIAL_STATUSES.notStarted) {
      const selectedModuleId = +moduleId;
      const selectedLessonId = +lessonId;
      const materialId = material.id;
      const payload = { ...material, status: MATERIAL_STATUSES.inProgress };

      store.dispatch(studentThunks.updateMaterialStatus({ id: materialId, status: MATERIAL_STATUSES.inProgress })).then(
        (res) =>
          res.meta.requestStatus === 'fulfilled' &&
          store.dispatch(
            studentActions.replaceMaterial({
              selectedModuleId,
              selectedLessonId,
              materialId,
              value: payload,
            })
          )
      );
    }
  };
  useEffect(() => {
    !program && store.dispatch(PROGRAM_FETCH_BY_ROLES[currentRole](groupId ? groupId : id));
  }, [program, currentRole, id, groupId]);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList title={t('types.material.namePlural')} />
        </ErrorBoundary>
      )}
      {!isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderPageMobile
            isToShowProgramMenuBtn
            toggleProgramMobileMenu={toggleProgramMobileMenu}
            selectedMaterialPriorityLabel={selectedPriority?.label}
          />
        </ErrorBoundary>
      )}
      {programIsLoading ? (
        <Loader />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          height="100%"
          sx={{ overflowY: 'hidden' }}
        >
          {(isDesktop || isProgramMenuOpen) && (
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramNavigation program={program} />
            </ErrorBoundary>
          )}
          <ELementWrapper
            width="100%"
            height="100%"
            flex={3}
            padding={isMobile ? 0 : 2}
            display={isDesktop ? 'flex' : isProgramMenuOpen && 'none'}
            sx={{ backgroundColor: isDesktop ? 'extra.white' : 'transparent', pt: !isDesktop ? 0 : 2 }}
          >
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <ProgramViewTopBar
                program={program}
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                selectedPriority={selectedPriority}
                selectedMaterial={selectedMaterial}
                allMaterials={filteredMaterialsList}
                setFilteredMaterialsList={setFilteredMaterialsList}
                hideProgramViewTopBar={isDesktop ? false : true}
                hideToggleView={isDesktop ? false : true}
              />
            </ErrorBoundary>
            <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
              <MaterialsList
                selectedModule={selectedModule}
                selectedLesson={selectedLesson}
                handleSelectMaterial={handleSelectMaterial}
                filteredMaterialsList={filteredMaterialsList}
              />
            </ErrorBoundary>
          </ELementWrapper>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(MaterialsPage);
