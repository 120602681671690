import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  lighten,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { memo, useState } from 'react';
import { ASSIGNMENT_TYPE_ICONS, MATERIAL_TYPE_ICONS } from 'utils/constants/program';
import DeleteMaterial from './DeleteMaterial';
import EditMaterial from './EditMaterial';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/ducks/user';
import { useMaterialsViewContext } from 'services/context/materialsListViewContext';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import filters from 'filters';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';

const MaterialCard = ({ isAssignment, selectedModule, selectedLesson, material, onClick }) => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const isAdmin = currentRole === 'Admin';
  const isStudent = currentRole === 'Student';
  const theme = useTheme();

  const { materialsView } = useMaterialsViewContext();

  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  const isMobile = useMediaQuery(MOBILE_VIEW);
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Grid
        item
        sm={12}
        md={12}
        lg={materialsView === 'row' ? 12 : 6}
        width="100%"
        sx={(theme) => ({ [theme.breakpoints.down('sm')]: { '&:first-of-type': { pt: 0 } } })}
      >
        <Card
          sx={(theme) => ({
            ...(isAssignment &&
              material?.status?.name === 'ARCHIVED' && { backgroundColor: lighten(theme.palette.warning.main, 0.9) }),
            borderRadius: isDesktop ? 1 : 2,
            boxShadow:
              isAssignment && material?.status?.name === 'ARCHIVED'
                ? '0px 0px 4px 0px rgba(206, 198, 0, 0.10)'
                : '0px 0px 4px 0px rgba(77, 84, 201, 0.10)',
            cursor: isDesktop ? 'pointer' : '',
            position: 'relative',
            border: isDesktop ? '1px solid' : 'none',
            borderColor: isAssignment && material?.status?.name === 'ARCHIVED' ? 'warning.main' : 'primary.lighter',
            transition: 'all 0.5s',
            [theme.breakpoints.up('lg')]: {
              '&:hover': {
                backgroundColor: isAssignment && material?.status?.name === 'ARCHIVED' ? 'none' : 'primary.lighter',
                p: 0,
              },
            },
          })}
          onMouseEnter={isDesktop ? handleMouseEnter : null}
          onMouseLeave={isDesktop ? handleMouseLeave : null}
          onClick={isDesktop ? onClick : null}
        >
          <CardContent
            sx={{
              '&:last-child': { paddingBottom: 2 },
            }}
          >
            {isAdmin && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  opacity: isDesktop ? (isHovered ? '1' : '0') : 1,
                  transition: 'opacity 0.5s',
                  zIndex: 1,
                }}
              >
                <EditMaterial
                  isAssignment={isAssignment}
                  material={material}
                  selectedLesson={selectedLesson}
                  selectedModule={selectedModule}
                />
                {!isAssignment && (
                  <DeleteMaterial material={material} selectedLesson={selectedLesson} selectedModule={selectedModule} />
                )}
              </Box>
            )}

            {isStudent && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                }}
              >
                {!isAssignment && (
                  <>
                    {material?.status === 'DONE' && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant={isMobile ? 'capture' : 'body2'} color="success.main">
                          {t('types.material.passed')}
                        </Typography>
                        <CheckCircleOutlineIcon
                          sx={{ color: 'success.main', width: isDesktop ? 16 : 24, height: isDesktop ? 16 : 24 }}
                        />
                      </Stack>
                    )}
                    {material?.status === 'IN_PROGRESS' && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant={isMobile ? 'capture' : 'body2'} color="info.main">
                          {t('types.material.inProgress')}
                        </Typography>
                        <AccessTimeIcon
                          sx={{ color: 'info.main', width: isDesktop ? 16 : 24, height: isDesktop ? 16 : 24 }}
                        />
                      </Stack>
                    )}
                  </>
                )}
              </Box>
            )}

            <Stack gap={isDesktop ? 1 : 2} position="relative">
              {isAssignment && material.status?.name === 'ARCHIVED' && (
                <Typography
                  position="absolute"
                  top={0}
                  left={isDesktop ? 0 : 'calc(50% - 16px)'}
                  variant="body2"
                  fontWeight={700}
                  color="text.light"
                >
                  {material?.status?.label}
                </Typography>
              )}
              <Stack direction={isDesktop ? 'column' : 'row'} gap={1}>
                {!isAssignment && MATERIAL_TYPE_ICONS(theme)[material.type.name](isMobile ? 22 : 30)}
                {isAssignment && ASSIGNMENT_TYPE_ICONS(theme)[material.type.name](isMobile ? 22 : 30)}
                <Typography variant={isMobile ? 'capture' : 'body2'} color={'text.light'} alignSelf="center">
                  {material.type.label}
                </Typography>
              </Stack>
              {/** ---- Title of material for Mobile View --- */}
              {!isDesktop && (
                <Typography variant={isMobile ? 'body1' : 'subtitle2'} color="text.primary">
                  {material.number}. {material.name}
                </Typography>
              )}
              {/** ---- Button to view material for Mobile view --- */}
              {!isDesktop && (
                <Button sx={{ width: '100%', fontSize: 14, minHeight: 20, py: 1, fontWeight: 700 }} onClick={onClick}>
                  {t('base.buttons.view')}
                </Button>
              )}

              {isAssignment && (
                <Stack direction="row" gap={0.5} justifyContent={isDesktop ? 'center' : 'flex-start'}>
                  <Typography variant="capture" color="text.main">
                    {t('types.material.deadline')}:{' '}
                  </Typography>
                  <Typography variant="capture" color="text.main" fontWeight={700}>
                    {filters.date(material?.deadline, 'dd.MM.yyyy')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </CardContent>
        </Card>
        {/** ---- Title of material for Desktop view --- */}
        {isDesktop && (
          <Typography variant="body2" fontWeight={isHovered ? 600 : 400} color="text.main" textAlign="center" mt={0.5}>
            {material.number}. {material.name}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default memo(MaterialCard);
