import { FormControl, MenuItem, useMediaQuery } from '@mui/material';
import { memo, useState } from 'react';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import Select from 'views/form/Select';

const FILTER_OPTIONS = ['All', 'Presentation', 'Link', 'Article', 'YouTube video'];

const MaterialsFilter = ({ setFilteredMaterialsList, selectedLesson, selectedPriority }) => {
  const [selectedFilterType, setSelectedFilterType] = useState('All');

  const isMobile = useMediaQuery(MOBILE_VIEW);
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const handleFilterChange = (event) => {
    setSelectedFilterType(event);

    const allMaterials = selectedLesson?.materials.filter(
      (material) => material.priority.name === selectedPriority?.name
    );

    if (event === 'All') {
      setFilteredMaterialsList(allMaterials);
    } else {
      const filtered = allMaterials.filter((material) => material.type.label === event);
      setFilteredMaterialsList(filtered);
    }
  };

  return (
    <FormControl>
      <Select
        id="programsSortBy"
        value={selectedFilterType}
        displayEmpty
        onChange={handleFilterChange}
        sx={{
          fontSize: 14,
          color: 'primary.main',
          backgroundColor: !isDesktop ? 'transparent' : 'extra.white',
          boxShadow: 'none',

          '.MuiOutlinedInput-notchedOutline': { border: 1 },
          '& .MuiSelect-select': {
            padding: '4px 16px',
          },
          '& .MuiSelect-icon': {
            color: 'primary.main',
          },
        }}
      >
        {FILTER_OPTIONS.map((option) => {
          return (
            <MenuItem key={option} value={option} sx={{ fontSize: isMobile ? 14 : 16, minHeight: isMobile ? 32 : 48 }}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default memo(MaterialsFilter);
