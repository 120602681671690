import { Box, Link, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { memo } from 'react';

import { ReactComponent as JoinLesson } from '../../../../../theme/icons/join-icon.svg';
import { ELementWrapper } from 'views/common/styledComponents';
import { getCurrentDate } from 'utils/helpers';
import { MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

const LessonCard = ({ lesson }) => {
  const lessonDate = DateTime.fromISO(lesson.date);
  const isToday = lessonDate.hasSame(getCurrentDate(), 'day');
  const lessonStartTime = lessonDate.toFormat('HH:mm');
  const lessonEndTime = lesson?.lesson?.plannedDuration
    ? lessonDate.plus({ minutes: lesson?.lesson?.plannedDuration }).toFormat('HH:mm')
    : '-:-';
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <>
      <ELementWrapper
        gap={2}
        sx={{ p: 1 }}
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        overflow="hidden"
      >
        <Box>
          <Typography variant="body2" color="text.light">
            {`${lesson?.lesson?.number}. ${lesson?.lesson?.name}`}
          </Typography>
          <Typography variant="body1" color="text.dark">
            {`${lessonStartTime} - ${lessonEndTime}`}
          </Typography>
        </Box>
        <Box>
          {isToday ? (
            <Tooltip title={t('base.tooltips.joinMeet')}>
              <Stack
                component={Link}
                href={lesson?.link}
                target="_blank"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  textDecoration: 'none',
                  borderRadius: 1,
                  p: 1,
                  minWidth: '84px',
                  background: theme.palette.extra.gradient,
                  [theme.breakpoints.down('sm')]: { py: 0.5 },
                }}
              >
                <JoinLesson />
                <Typography variant={isMobile ? 'capture' : 'body2'} color="extra.white">
                  {t('base.buttons.joinMeet')}
                </Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '1px solid',
                borderRadius: 1,
                borderColor: 'primary.main',
                p: 1,
                minWidth: '84px',
                [theme.breakpoints.down('sm')]: { py: 0.5 },
              }}
            >
              <Stack flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h5" color="primary.main">
                  {lessonDate.get('day')}
                </Typography>
                <Typography variant={isMobile ? 'capture' : 'body2'} color="primary.main">
                  {lessonDate.setLocale(i18n.language === 'UA' ? 'UK' : i18n.language).monthLong}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>
      </ELementWrapper>
    </>
  );
};

export default memo(LessonCard);
