import { addMethod, object, ref, string, array } from 'yup';
import { REG_EXP } from './constants/common';
import i18next from 'i18next';

const t = i18next.t;

const getValidationMessage = (key) => {
  return t(`messages.errors.validation.${key}`);
};

/* --- Strong password validation  --- */

addMethod(string, 'strongPassword', strongPassword);
function strongPassword() {
  return this.test('strongPasswordTest', function (value) {
    const { path, createError } = this;

    if (value === null || value === '' || value === undefined) {
      return true;
    }

    switch (Boolean(value)) {
      case !REG_EXP.lower.test(value):
        return createError({
          path,
          message: getValidationMessage('lowerCase'),
        });
      case !REG_EXP.upper.test(value):
        return createError({
          path,
          message: getValidationMessage('upperCase'),
        });
      case !REG_EXP.digit.test(value):
        return createError({ path, message: getValidationMessage('digit') });
      case !REG_EXP.specChar.test(value):
        return createError({ path, message: getValidationMessage('specChar') });
      default:
        return true;
    }
  });
}

/* --- LOGIN FORM --- */

export const logInFormSchema = object({
  email: string(),
  password: string().required(() => getValidationMessage('required')),
});

/* --- FORGOT PASSWORD FORM --- */

export const forgotPasswordSchema = object({
  email: string()
    .trim()
    .email(() => getValidationMessage('email'))
    .required(() => getValidationMessage('required')),
});

/* --- RESET PASSWORD FORM --- */

export const resetPasswordSchema = object({
  token: string().required(() => getValidationMessage('required')),
  password: string()
    .required(() => getValidationMessage('required'))
    .strongPassword()
    .min(8, () => getValidationMessage('passwordMinLength')),
  confirmPassword: string()
    .required(() => getValidationMessage('required'))
    .oneOf([ref('password')], () => getValidationMessage('confirmPassword')),
});

/*  ---------- ADD MATERIAL FORM ------------------- */

export const validationMaterialForm = object({
  number: string().required(() => getValidationMessage('required')),
  name: string().required(() => getValidationMessage('required')),
  lesson: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  priority: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  type: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  link: string().test({
    test: function (value) {
      if (this.parent.type?.name === 'YOUTUBE' || this.parent.type?.name === 'LINK') {
        if (!value) {
          return this.createError({
            message: () => getValidationMessage('required'),
            path: this.path,
          });
        }
        if (!REG_EXP.url.test(value)) {
          return this.createError({
            message: () => getValidationMessage('urlMatch'),
            path: this.path,
          });
        }
      }
      return true;
    },
  }),
  //TODO: validation if file is selected
  // fileId: string().test({
  //   test: function (value) {
  //     if (this.parent.type?.name === 'PRESENTATION') {
  //       if (!value) {
  //         return this.createError({
  //           message: messages.errors.validation.uploadFile,
  //           path: this.path,
  //         });
  //       }
  //     }
  //     return true;
  //   },
  // }),
});

/*  ---------- ADD ARTICLE FORM ------------------- */

export const validationArticleForm = object({
  name: string().required(() => getValidationMessage('required')),
  program: object().nullable(),
  text: string(),
});

/*  ---------- ADD ASSIGNMENT FORM ------------------- */
export const validationAssignmentForm = object({
  number: string().required(() => getValidationMessage('required')),
  name: string().required(() => getValidationMessage('required')),
  program: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  module: object().nullable(),
  lesson: object().nullable(),
  status: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  type: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  requirement: object()
    .nullable()
    .required(() => getValidationMessage('required')),
  description: string(),
  documents: array().of(string()),
  deadlineDays: string(),
});

/* --- CHANGE PASSWORD FORM --- */

export const changePasswordFormSchema = object({
  newPassword: string()
    .required(() => getValidationMessage("required"))
    .strongPassword()
    .min(8, () => getValidationMessage("passwordMinLength")),

  confirmPassword: string()
    .required(() => getValidationMessage("required"))
    .oneOf([ref("newPassword")], () => getValidationMessage("confirmPassword")),
});