import { Tooltip } from '@mui/material';
import './custom-month-event.css';

import { DateTime } from 'luxon';

const CustomMonthEvent = ({ event }) => {
  const incomingDate = new Date(event.start);
  const timeOfEvent = DateTime.fromJSDate(incomingDate).toFormat('HH:mm');
  return (
    <Tooltip title={`${event.group} | ${event.title}`} placement="right-end">
      <div className="rbc-event-content" title="">
        <span className="rbc-event-time">{timeOfEvent}</span>
        <span className="rbc-event-title"> {event.title}</span>
      </div>
    </Tooltip>
  );
};

export default CustomMonthEvent;
