import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { userSelectors } from 'store/ducks/user';
import {
  CALENDAR_FETCH_BY_ROLES,
  CALENDAR_IS_LOADING_BY_ROLES,
  CALENDAR_SELECTORS_BY_ROLES,
} from 'utils/constants/calendar';
import { getCurrentDate, getStartAndEndOfMonth } from 'utils/helpers';
import HeaderList from 'views/common/HeaderList';
import { ELementWrapper, PageWrapper } from 'views/common/styledComponents';
import { DateTime } from 'luxon';

import BigCalendar from './components/BigCalendar';
import { Button, Stack, useMediaQuery } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/common';
import Loader from 'views/common/Loader';
import { LessonCard } from '../dashboard/components';
import { sortBy } from 'utils/lo';
import EventsForTodayTomorrow from './components/EventsForTodayTomorrow';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import i18n from 'i18n/i18n';

const CalendarPage = () => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const calendarData = useSelector(CALENDAR_SELECTORS_BY_ROLES[currentRole]);
  const calendarDataLoading = useSelector(CALENDAR_IS_LOADING_BY_ROLES[currentRole]);

  const [todaySessions, setTodaySessions] = useState([]);
  const [tomorrowSessions, setTomorrowSessions] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);

  const [filter, setFilter] = useState({ isShown: true, value: null });

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());

  const { startOfMonth, endOfMonth } = getStartAndEndOfMonth(selectedDate);

  const query = `?from=${startOfMonth}&to=${endOfMonth}`;

  const { t } = useTranslation();

  useEffect(() => {
    store.dispatch(CALENDAR_FETCH_BY_ROLES[currentRole](query));
  }, [query, currentRole]);

  useEffect(() => {
    filter.value
      ? setFilteredGroups(calendarData.filter((event) => event.group.id === filter?.value?.resourceId))
      : setFilteredGroups(calendarData);
  }, [filter.value, calendarData]);

  useEffect(() => {
    if (calendarData) {
      const uniqueGroups = Array.from(
        new Map(
          calendarData.map((item) => [item.group.id, { resourceId: item.group.id, resourceTitle: item.group.name }])
        ).values()
      );
      setGroups(uniqueGroups);
    }
  }, [calendarData]);

  useEffect(() => {
    /** --- after first fetch of calendar data store sessions for today and tomorrow in order to display
     * events under small calendar */

    if (isFirstLoad && !calendarDataLoading) {
      const today = DateTime.local().startOf('day');
      const tomorrow = today.plus({ days: 1 });

      const todaySessions = calendarData.filter((event) => {
        const eventData = DateTime.fromISO(event.date).startOf('day');
        return today.equals(eventData);
      });

      const tomorrowSessions = calendarData.filter((event) => {
        const eventData = DateTime.fromISO(event.date).startOf('day');
        return tomorrow.equals(eventData);
      });

      setTodaySessions(todaySessions);
      setTomorrowSessions(tomorrowSessions);
      setIsFirstLoad(false);
    }
  }, [calendarData, isFirstLoad, calendarDataLoading]);

  /** --- handle change date in big calendar ---- */
  const handleDateChange = useCallback(
    (newDate) => {
      const parsedDate = new Date(newDate);
      const luxonDateTime = DateTime.fromJSDate(parsedDate);
      setSelectedDate(luxonDateTime);
    },
    [setSelectedDate]
  );
  /** --- handle change date in small MUI calendar ---- */
  const handleOnChange = useCallback((newDate) => setSelectedDate(newDate), [setSelectedDate]);
  const handleMonthChange = useCallback((newDate) => setSelectedDate(newDate), [setSelectedDate]);
  const goToday = () => {
    const today = DateTime.local().startOf('day');
    setSelectedDate(today);
  };
  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.calendar.name')}
            filter={filter}
            setFilter={setFilter}
            groups={groups}
            calendarDataLoading={calendarDataLoading}
          />
        </ErrorBoundary>
      )}
      <Stack
        flexDirection="row"
        alignItems="flex-start"
        gap={2}
        sx={{
          height: 'calc(100% - 92px)',
          width: '100%',
          mt: isDesktop ? 0 : 2,
        }}
      >
        <Stack flexDirection="column" alignItems="center" gap={2} height="100%" flex={isDesktop ? 0.2 : 1}>
          <ELementWrapper
            sx={{
              width: '100%',
              p: 2,
              pt: 0,
              alignItems: 'center',
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterLuxon}
              adapterLocale={i18n.language === 'UA' ? 'UK' : i18n.language}
            >
              <DateCalendar
                views={['year', 'month', 'day']}
                onChange={handleOnChange}
                value={selectedDate}
                sx={{
                  maxWidth: 252,
                  '& .MuiPickersCalendarHeader-root': { paddingLeft: 0, paddingRight: 0 },
                  '& .MuiYearCalendar-root': { maxWidth: 265 },
                  '& .MuiMonthCalendar-root': { maxWidth: 265 },
                  '& .MuiPickersYear-yearButton': { fontSize: 16, width: 64 },
                  '& .MuiPickersMonth-monthButton': { fontSize: 16, width: 64 },
                }}
                onMonthChange={handleMonthChange}
              />
            </LocalizationProvider>
            <Button variant="outlined" onClick={goToday} sx={{ width: 252 }}>
              {t('types.calendar.today')}
            </Button>
          </ELementWrapper>

          <ELementWrapper width="100%" height="100%" sx={{ overflowY: 'auto', p: isMobile ? 0 : 2 }}>
            {calendarDataLoading && <Loader />}

            {/** --- Events for month in mobile view ---  */}
            {isMobile && (
              <>
                {!calendarDataLoading && (
                  <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                    <Stack gap={1}>
                      {sortBy(calendarData, 'date').map((event) => {
                        return <LessonCard lesson={event} key={event.id} />;
                      })}
                    </Stack>
                  </ErrorBoundary>
                )}
              </>
            )}
            {/** --- Events for today and tomorrow in Desktop view ---  */}
            {!isMobile && (
              <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
                <EventsForTodayTomorrow todaySessions={todaySessions} tomorrowSessions={tomorrowSessions} />
              </ErrorBoundary>
            )}
          </ELementWrapper>
        </Stack>

        {!isMobile && (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <ELementWrapper flex={0.8} sx={{ height: '100%', p: 2, width: 'calc(100% - 300px)' }}>
              <BigCalendar
                selectedDate={selectedDate}
                calendarData={filteredGroups}
                handleDateChange={handleDateChange}
                resources={groups}
              />
            </ELementWrapper>
          </ErrorBoundary>
        )}
      </Stack>
    </PageWrapper>
  );
};

export default memo(CalendarPage);
